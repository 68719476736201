<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset
            id="promotionInfo"
            :title="$t('Basic Information')"
          >
            <b-form-group
              :label="`*`+$t('Promotion name')+`:`"
              label-cols-md="12"
            >
              <validation-provider
                #default="{ errors }"
                name="Promotion name"
                rules="required"
              >
                <b-form-input
                  v-model="model.name"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Promotion name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col md="5">
                <b-form-group
                  :label="`*${$t('Flash sale day')}`"
                  label-cols-md="12"
                  class="inline-time-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="flash sale date"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.use_date"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Flash sale day')"
                      class="mb-1"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  </validation-provider>

                  <flat-pickr
                    v-model="model.use_date"
                    class="form-control x-input-time"
                    :config="{
                      minDate: 'today',
                      dateFormat: 'Y-m-d',
                      inline: true
                    }"
                    placeholder="Flash sale day"
                    @on-close="onDatePicker"
                  />
                </b-form-group>
              </b-col>

              <b-col md="7">
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="`*${$t('Time from')}`"
                      label-cols-md="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="time from"
                        rules="required"
                      >
                        <b-form-timepicker
                          v-model="model.time_from"
                          show-seconds
                          :state="errors.length > 0 ? false : null"
                          locale="vi"
                          :hour12="false"
                          @input="checkTime()"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      :label="`*${$t('Time to')}`"
                      label-cols-md="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="time to"
                        rules="required"
                      >
                        <b-form-timepicker
                          v-model="model.time_to"
                          show-seconds
                          :state="errors.length > 0 ? false : null"
                          locale="vi"
                          :hour12="false"
                          hour-cycle="h23"
                          @input="checkTime()"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="list-flashsale-live">
                  <table>
                    <thead>
                      <tr>
                        <th>{{ $t('Flash sale upcoming') }}</th>
                        <th>{{ $t('Time slot') }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="listOld.length > 0">
                      <tr
                        v-for="(item, index) in listOld"
                        :key="index"
                        :class="item.err"
                      >
                        <td>{{ item.name }}</td>
                        <td>{{ item.time_from }} - {{ item.time_to }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <td colspan="2">
                        <i>{{ $t('All time slots are empty') }}</i>
                      </td>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>

            <b-form-group
              class="mb-0 mt-1"
              :label="$t('Active')+`:`"
              label-cols-md="2"
            >
              <b-form-checkbox
                v-model="model.is_active"
                checked="true"
                class="custom-control-success d-flex align-items-center"
                name="check-button"
                switch
              />
            </b-form-group>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div>
            <Fieldset
              id="avatar"
              :title="$t('Avatar')"
            >
              <FeatureImage
                :avatar="model.avatar"
                style="max-width: 177px"
                @previewFiles="previewFiles"
              />
            </Fieldset>
          </div>
        </b-col>
        <b-col md="12">
          <Fieldset
            id="promotionRole"
            :title="$t('Advanced Option')"
          >
            <div
              class="d-flex flex-wrap justify-content-between mb-2"
              style="gap: 8px;"
            >
              <h4>{{ $t('Products participating in the Shops Flash Sale') }}</h4>
              <b-button
                v-b-modal.modal-xl
                variant="primary"
                type="button"
                class="ml-3"
              >
                {{ $t('Add product') }}
              </b-button>
            </div>

            <!-- Advance -->
            <div class="advance-block mb-2">
              <b-row>
                <b-col
                  lg="3"
                  class="d-flex flex-column justify-content-center"
                >
                  {{ $t('Batch setting') }}
                  <div><strong>{{ model.selected.length }}</strong>&nbsp;{{ $t('selected products') }}</div>
                </b-col>
                <b-col style="max-width: 125px;">
                  <b-form-group
                    :label="$t('Discount amount')"
                  >
                    <b-input-group
                      append="%"
                      class="mx-auto"
                    >
                      <b-form-input
                        v-model="limitOn.percent"
                        type="number"
                        min="1"
                        max="100"
                        class="text-right"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    :label="$t('Number of promotional products')"
                    label-for="basicInput"
                    class="limit-form-group"
                  >
                    <b-form-select
                      v-model="limitOn.promotion"
                      :options="limitProductsOptions"
                    />
                    <b-form-input
                      v-model="limitOn.promotionQuantity"
                      type="number"
                      min="1"
                      :placeholder="`${ $t('Minimum at') } 1`"
                      :disabled="!limitOn.promotion"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    :label="$t('Limited products per person')"
                    label-for="basicInput"
                    class="limit-form-group"
                  >
                    <b-form-select
                      v-model="limitOn.person"
                      :options="limitProductsOptions"
                    />
                    <b-form-input
                      v-model="limitOn.personQuantity"
                      type="number"
                      min="1"
                      :placeholder="`${ $t('Minimum at') } 1`"
                      :disabled="!limitOn.person"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  lg="2"
                  md="6"
                  class="d-flex flex-column justify-content-center"
                  style="gap: 8px;"
                >
                  <b-button
                    variant="gradient-success"
                    size="sm"
                    pill
                    class="w-100"
                    @click="advanceApply"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Apply') }}</span>
                  </b-button>

                  <b-button
                    variant="gradient-danger"
                    size="sm"
                    pill
                    class="w-100"
                    :disabled="model.selected.length === 0"
                    @click="advanceDelete"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span class="align-middle">{{ $t('Delete') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <PromotionItems v-model="model" />
          </Fieldset>
          <PopupAddProduct v-model="model" />
        </b-col>
        <b-col md="12">
          <BCard>
            <b-button
              variant="info"
              type="button"
              @click.prevent="validationForm"
            >
              {{ $t('Create new') }}
            </b-button>
          </BCard>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormCheckbox,
  BInputGroup,
  BFormSelect,
  BFormTimepicker,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PopupAddProduct from './components/PopupAddProduct.vue'
import PromotionItems from './components/PromotionItems.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    flatPickr,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    PopupAddProduct,
    BInputGroup,
    BFormSelect,
    PromotionItems,
    BFormTimepicker,
  },
  directives: {
    Ripple,
  },

  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: this.avatarDefault(),
        name: null,
        percent: null,
        date_from_to: null,
        is_active: true,
        list_product: [],
        content: null,
        selectedAll: false,
        selected: [],
        validCheckAll: 0,
        use_date: null,
        time_from: null,
        time_to: null,
      },
      pageLength: 10,
      searchTerm: '',
      limitProductsOptions: [
        {
          value: null,
          text: 'Không giới hạn',
        },
        {
          value: 'limit',
          text: 'Giới hạn',
        },
      ],
      promotionProductsSelected: 0,
      promotionProductsSelectedCount: 0,
      limitOn: {
        promotion: null,
        person: null,
        promotionQuantity: null,
        personQuantity: null,
        percent: null,
      },
      paramList: {
        use_date: null,
      },
      listOld: [],
    }
  },
  methods: {
    onDatePicker(selectedDates, dateStr) {
      this.paramList.use_date = dateStr
      this.getList()
    },
    async getList() {
      try {
        let strParams = ''
        Object.keys(this.paramList).map(isKey => {
          if (this.paramList[isKey]) {
            strParams += `&${isKey}=${this.paramList[isKey]}`
          }
          return true
        })
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/v2/flash_sales?site_id=${siteId}${strParams}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const temp = []
            res.data.data.items.map(val => {
              const item = val
              const strtimeFrom = val.time_from.split(':')
              const nTimeFrom = (Number(strtimeFrom[0]) * 60 * 60) + (Number(strtimeFrom[1]) * 60) + Number(strtimeFrom[2])
              item.ntime_from = nTimeFrom

              const strtimeTo = val.time_to.split(':')
              const nTimeTo = (Number(strtimeTo[0]) * 60 * 60) + (Number(strtimeTo[1]) * 60) + Number(strtimeTo[2])
              item.ntime_to = nTimeTo

              item.err = ''
              temp.push(item)

              return true
            })
            this.listOld = temp
            this.checkTime()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    checkTime() {
      if (this.model.time_from && this.model.time_to) {
        const strtimeFrom = this.model.time_from.split(':')
        const nTimeFrom = (Number(strtimeFrom[0]) * 60 * 60) + (Number(strtimeFrom[1]) * 60) + Number(strtimeFrom[2])
        const strtimeTo = this.model.time_to.split(':')
        const nTimeTo = (Number(strtimeTo[0]) * 60 * 60) + (Number(strtimeTo[1]) * 60) + Number(strtimeTo[2])
        this.listOld.map((val, index) => {
          if ((nTimeFrom >= val.ntime_from && nTimeFrom <= val.ntime_to) || (nTimeTo >= val.ntime_from && nTimeTo <= val.ntime_to)) {
            this.listOld[index].err = 'err'
          } else {
            this.listOld[index].err = ''
          }
          return true
        })

        this.listOld.sort((a, b) => {
          const nameA = a.err.toUpperCase() // ignore upper and lowercase
          const nameB = b.err.toUpperCase() // ignore upper and lowercase
          if (nameA > nameB) {
            return -1
          }
          if (nameA < nameB) {
            return 1
          }

          // names must be equal
          return 0
        })
      }
    },
    async validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              name: this.model.name,
              is_active: Number(this.model.is_active === true ? 1 : 0),
              use_date: this.model.use_date,
              time_from: this.model.time_from,
              time_to: this.model.time_to,
              type: 0,
            }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }

            const listPromotionWarehouse = []
            let numEr = 0
            this.model.list_product.map((val, index) => {
              const findData = this.model.list_product[index]
              findData.children.map((val2, index2) => {
                const temp = this.model.list_product[index].children[index2]
                if (val2.valid.status === true) {
                  listPromotionWarehouse.push({
                    product_id: val.id,
                    limit_buy: Number(val.limitProducts),
                    warehouse_id: val2.id,
                    limit_quantity: val2.is_limit_warehouse === 'limit' ? val2.limit_warehouse : 0,
                    percent: val2.percent,
                    price_new: val2.discount,
                    is_active: val2.status ? 1 : 0,
                  })
                  if (Number(temp.percent) === 0 || temp.percent > 100 || temp.percent < 0) {
                    temp.valid.ok_percent = 'error'
                    numEr += 1
                    temp.valid.show = true
                    temp.valid.status = false
                    temp.status = false
                  } else {
                    temp.valid.ok_percent = ''
                  }
                } else {
                  numEr += 1
                }
                return true
              })
              return true
            })

            if (numEr !== 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('Invalid product list'),
                },
              })
              return false
            }
            params.list_flashsale = JSON.stringify(listPromotionWarehouse)

            if (listPromotionWarehouse.length === 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Opps! Something wrong',
                  icon: 'AlertOctagonIcon',
                  variant: 'danger',
                  text: String('No products selected yet'),
                },
              })
              return false
            }

            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/v2/flash_sale`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Promotion success',
                  },
                })
                this.$router.push('/flash-sale/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String('Form validate not success'),
            },
          })
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    handlerDelete(id) {
      this.model.list_product.splice(this.model.list_product.findIndex(val => val.id === id), 1)
    },
    selectionChanged() {
      this.promotionProductsSelected = this.$refs['promotion-products'].selectedRows
      this.promotionProductsSelectedCount = this.$refs['promotion-products'].selectedRows.length
    },
    advanceApply() {
      if (this.model.selected.length > 0) {
        this.model.selected.map(val => {
          const findData = this.model.list_product.find(x => x.id === val)
          if (findData) {
            findData.limitProducts = this.limitOn.personQuantity
            findData.isLimitProduct = this.limitOn.person
            findData.children.map((val2, index) => {
              findData.children[index].percent = this.limitOn.percent
              findData.children[index].discount = findData.children[index].price - ((findData.children[index].price / 100) * this.limitOn.percent)
              findData.children[index].limit_warehouse = this.limitOn.promotionQuantity
              findData.children[index].is_limit_warehouse = this.limitOn.promotion
              return true
            })
          }
          return true
        })
      } else {
        this.model.list_product.map((val, index) => {
          const findData = this.model.list_product[index]
          findData.limitProducts = this.limitOn.personQuantity
          findData.isLimitProduct = this.limitOn.person
          findData.children.map((val2, index2) => {
            findData.children[index2].percent = this.limitOn.percent
            findData.children[index2].discount = findData.children[index2].price - ((findData.children[index2].price / 100) * this.limitOn.percent)
            findData.children[index2].limit_warehouse = this.limitOn.promotionQuantity
            findData.children[index2].is_limit_warehouse = this.limitOn.promotion
            return true
          })
          return true
        })
      }
      this.model.validCheckAll = Math.random()
    },
    advanceDelete() {
      const temp = JSON.parse(JSON.stringify(this.model.selected))
      temp.map(id => {
        this.model.list_product.splice(this.model.list_product.find(val => val.id === id), 1)
        return true
      })
      this.model.selected = []
      this.model.selectedAll = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.inline-time-group{
  .flatpickr-calendar.inline{
    width: 100%;
  }
  .dayContainer{
    max-width: 100%;
    width: 100%;
  }
  .flatpickr-days{
    width: 100%;
  }
  .flatpickr-day{
    max-width: 60px;
    border-radius: 0;
  }
}
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
  }
  td {
    vertical-align: middle;
  }
  .input-group {
    width: 165px;
    max-width: 150px;
  }
  .form-group {
    width: 165px;
  }
  .limit-form-group {
    max-width: 180px;

    label {
      max-width: 130px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 60px;
    }
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
  .avatar-name {
    display: flex;
    align-items: center;
    min-width: 200px;

    .name {
      max-width: 180px;
    }
  }
}
.advance-block {
  background: #F6F6F6;
  padding: 1rem;

  .form-group {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
  }
  .form-group {
    height: 100%;

    > div {
      margin-top: auto;
    }
  }
  .limit-form-group {
    label {
      max-width: 130px;
    }
    > div {
      display: grid;
      grid-template-columns: 1fr 60px;
    }
    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}
.list-flashsale-live{
  max-height: 305px;
  overflow-y: auto;
  table{
    width: 100%;
    border-collapse: collapse;
    tr.err{
      color: red;
    }
    th,td{
      padding: 10px;
      border: 1px solid #ddd;
    }
  }
  // Simple
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0,0,0,0.1);
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background: rgba(0,0,0,0.2);
  }
  &::-webkit-scrollbar-thumb:hover{
    background: rgba(0,0,0,0.4);
  }
  &::-webkit-scrollbar-thumb:active{
    background: rgba(0,0,0,.9);
  }
}
.x-input-time{
  display: none
}
</style>
