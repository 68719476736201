<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-modal
    id="modal-xl"
    ok-title="Save"
    centered
    size="xl"
    :title="$t('Add product to list promotion')"
  >
    <b-row>
      <b-col lg="3">
        <ProductCategorySort
          :list="catItems"
          @handlerChangeCategory="handlerChangeCategory"
        />
      </b-col>
      <b-col lg="9">
        <b-row>
          <b-col lg="6">
            <div class="d-flex">
              <b-input-group class="input-group-merge w-100 round">
                <!-- <b-input-group-prepend is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-prepend> -->
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
              </b-input-group>
              <b-button class="ml-2 text-nowrap" @click="handlerSearch">
                {{ $t('Search') }}
              </b-button>
              <b-button class="ml-2 text-nowrap" @click="resetFilter">
                {{ $t('Reset') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
        <ProductList v-model="value" :list="items" />
      </b-col>
    </b-row>
    <!-- <ProductList v-model="value" :list="items" /> -->
  </b-modal>
</template>
<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable array-callback-return */
/* eslint-disable object-shorthand */
import {
  BRow,
  BCol,
  BInputGroup,
  // BInputGroupPrepend,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProductCategorySort from './ProductCategorySort.vue'
import ProductList from './ProductList.vue'

export default {
  components: {
    BInputGroup,
    BRow,
    BCol,
    ProductCategorySort,
    ProductList,
    // BInputGroupPrepend,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    // productList: {
    //   type: Object,
    //   default() {
    //     return {}
    //   },
    // },
  },
  data() {
    return {
      items: [],
      catItems: [],
      productListTemp: [],
      searchQuery: null,
      category_id: null,
    }
  },
  watch: {
    'value.date_from_to'() {
      // console.log(this.value.date_from_to)
      this.loadList()
    },
  },
  mounted() {
    this.loadList()
    this.loadListCategory()
  },
  methods: {
    handlerChangeCategory(data) {
      this.category_id = data.item.id
      this.loadList(this.category_id)
    },
    handlerSearch() {
      this.loadList()
    },
    resetFilter() {
      this.category_id = null
      this.searchQuery = null
      this.loadList()
    },
    async loadList(categoryID = null) {
      try {
        let params = ''
        if (this.value.date_from_to !== null) {
          if (this.value.date_from_to.split(' to ').length > 1) {
            const dateFromTo = this.value.date_from_to.split(' to ')
            params = `&date_from=${dateFromTo[0]}&date_to=${dateFromTo[1]}`
          }
        }
        if (categoryID) {
          params += `&category_id=${categoryID}`
        }
        if (this.searchQuery) {
          params += `&name=${this.searchQuery}`
        }
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?v=1${params}&limit=20&site_id=${siteId}&sort[]=id,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = []
            this.productListTemp = []
            res.data.data.items.map(val => {
              const warehouse = []
              val.warehouse.map(val2 => {
                let temp = []
                try {
                  temp = JSON.parse(val2.name_attribute || '[]')
                } catch (error) { return false }
                let nameAttr = ''
                temp.map(val3 => {
                  nameAttr += `${val3.value}-`
                })
                nameAttr = nameAttr.slice(0, -1)
                const valid = {
                  show: false,
                  status: true,
                  warning: false,
                  ok_name: '',
                  ok_inventory: '',
                  ok_price: '',
                  ok_discount: '',
                  ok_percent: '',
                  ok_limit_warehouse: '',
                  ok_is_limit_warehouse: '',
                  ok_status: '',
                }
                if (val2.quantity === 0) {
                  valid.ok_inventory = 'warning'
                  valid.warning = true
                }
                warehouse.push({
                  id: val2.id,
                  name: nameAttr,
                  id_prod: val.id_number,
                  inventory: val2.quantity,
                  price: val2.price,
                  discount: val2.price,
                  percent: 0,
                  limit_warehouse: 0,
                  is_limit_warehouse: null,
                  status: !valid.warning,
                  valid: valid,
                })
                return true
              })
              const item = {
                mode: 'span',
                avatar: val.avatar,
                created_at: val.created_at,
                id: val.id_number,
                is_active: val.is_active,
                name: val.name,
                position: val.position,
                // price: val.price,
                sku: val.sku,
                category_slug: val.category_slug,
                list_id_promotion: val.list_id_promotion,
                list_promotion: val.list_promotion,
                isLimitProduct: null,
                limitProducts: 0,
                isLimitOnPerson: null,
                limit_products_on_person: null,
                discount_price: val.price,
                discount_percent: 0,
                label: val.name,
                children: warehouse,
                warehouse: val.warehouse,
              }
              this.items.push(item)
              this.productListTemp.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadListCategory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.catItems = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
@media (max-width: 991px)
  .w-100
    margin-top: 50px

</style>
